<template>
  <review-card title="Cromossomos(as)">
    <template #title>
      <v-card-title class="d-flex align-center justify-center justify-sm-start">
        <v-icon large class="mr-2" color="orange">warning</v-icon>
        <h5>Sobre o Biomagnetismo Medicinal</h5>
      </v-card-title>
    </template>
    <div class="pa-3 body-2">
      <p>
        O Biomagnetismo Medicinal é uma técnica que utiliza campos magnéticos
        estáticos gerados por imãs terapêuticos aplicados externamente ao
        organismo sobre disfunções bioeletromagnéticas denominadas Pares
        Biomagnéticos. Os Pares Biomagnéticos são gerados por campos magnéticos
        em desequilíbrio no corpo humano que se formam através de distorções
        bioelétricas, que dificultam a comunicação entre os sistemas orgânicos,
        como, imunológico, límbico, endocrinológico, neurológico, linfático,
        excretor, etc.
      </p>
      <p>
        O objetivo do Biomagnetismo Medicinal é despolarizar as cargas dos
        elementos bioquímicos que mantém os Pares Biomagnéticos, regular o
        equilíbrio bioenergético do organismo, devolver o Nível Normal de
        Energia (NEN) dos sistemas envolvidos e como consequência o ajuste do pH
        da microrregião de abrangência dos Pares Biomagnéticos envolvidos
        estimulando o organismo a homeostase ou saúde.
      </p>
      <p>
        A aplicação do Biomagnetismo visa devolver ao corpo a condição para que
        ele próprio seja capaz de eliminar agentes patogênicos, como vírus,
        fungos, bactérias e parasitas, além de estimular a correção de
        disfunções glandulares e a desintoxicar.
      </p>
      <p>
        O tratamento não implica necessariamente na cura de doenças e o
        tratamento realizado não é medicina alopática, não substitui o
        tratamento médico ou de outro profissional da saúde. O sistema
        terapêutico não oferece quaisquer promessas ou garantias sobre os
        resultados esperados, bem como não é fornecido nenhum diagnóstico
        médico, mudança ou alteração nas prescrições médicas já existentes sendo
        a finalidade do tratamento estabilizar, normalizar e equilibrar o
        sistema bioenergético para que o organismo possa se recuperar, de acordo
        com suas possibilidades, de forma total ou parcial dos sinais e sintomas
        gerados pelo Pares Biomagnéticos.
      </p>
      <p>
        Neste relatório são apresentados os Pares Biomagnéticos tratados com os
        ímãs durante o atendimento de Biomagnetismo Medicinal e suas
        características de acordo com os manuais criados a partir dos estudos do
        Dr. Isaac Goiz Durán, que desenvolveu do sistema terapêutico.
      </p>
    </div>
  </review-card>
</template>

<script>
export default {
  name: "Disclaimer",
};
</script>

<style scoped></style>
